import React, { useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import logo from "../assets/BBIS-logo.png";
import "../assets/css/Navigation.css";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menuItems from "./menuItems.json";
import NavLink from "../components/NavLink";
import CallBackForm from "../components/CallBackForm";

const StyledPhoneLink = styled.a`
  color: #007bff;
`;

const subItemSelected = (location, subItems) => {
  let selected = false;
  if (subItems && subItems.find((item) => item.url.startsWith(location))) {
    selected = true;
  }
  return selected;
};

const Navigation = () => {
  const location = useLocation();
  const [menuOpen, toggleMenu] = useState(false);
  const [subItem, toggleSubItem] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="navbar">
      <div className="menu-branding">
        <Link href="/">
          <img
            src={logo}
            height="75"
            className="d-inline-block align-top left-space"
            alt="BBIS Commercial Heating Logo"
          />
        </Link>
      </div>

      <div className="menu-toggle">
        <div
          onClick={() => (menuOpen ? toggleMenu(false) : toggleMenu(true))}
          className={`animated-icon1 ${menuOpen ? "open" : ""}`}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={`nav-items${menuOpen ? " open" : ""}`}>
        <div className="nav-item">
          <Button onClick={handleShow}>Request Callback</Button>
        </div>
        <div className="nav-item">
          <StyledPhoneLink className="nav-link" href="tel:+448002289800">
            <span className="fa fa-phone" /> 0800 2289 800
          </StyledPhoneLink>
        </div>
        {menuItems.map((item, index) => (
          <div
            key={index}
            className={`nav-item${subItem === index ? " active" : ""} `}
          >
            <NavLink
              exact={true}
              link={item.url}
              onClick={() => {
                subItem === index ? toggleSubItem(null) : toggleSubItem(index);
                item.subitems ? toggleMenu(true) : toggleMenu(false);
              }}
              className={`nav-link ${item.class ? item.class : ""} ${
                location.pathname !== "/"
                  ? subItemSelected(location.pathname, item.subitems)
                    ? "active"
                    : ""
                  : ""
              }`}
            >
              {item.name}
              {item.subitems && (
                <>
                  {" "}
                  <FontAwesomeIcon icon={faCaretDown} size="1x" />
                </>
              )}
            </NavLink>
            {item.subitems && (
              <div
                className={`dropdown-content${
                  subItem === index ? " show-menu" : ""
                }`}
              >
                {item.subitems.map((subitem, index) => (
                  <div key={index} className="sub-item">
                    <Link
                      exact={true}
                      to={subitem.url}
                      onClick={() => {
                        toggleMenu(false);
                        toggleSubItem(null);
                      }}
                      className={`nav-link ${
                        subitem.class ? subitem.class : ""
                      }`}
                    >
                      {subitem.name}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <CallBackForm handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default Navigation;
