import React from "react";
import "../assets/css/Social.css";
export const Social = () => {
  return (
    <div className="social">
      <div className="social-links">
        <a
          href="https://www.facebook.com/BBISCommercialheating/"
          className="social-link"
          aria-label="Facebook Link"
        >
          <span className="fa fa-facebook"></span>
        </a>
        <a href="https://twitter.com/BbisLtd" className="social-link" aria-label="Twitter Link">
          <span className="fa fa-twitter"></span>
        </a>
        <a
          href="https://www.instagram.com/bbiscommercialheatingltd/"
          className="social-link"
          aria-label="Instagram Link"
        >
          <span className="fa fa-instagram"></span>
        </a>
        <a href="https://www.linkedin.com/in/bbis/" className="social-link" aria-label="Linkedin Link">
          <span className="fa fa-linkedin"></span>
        </a>
      </div>
    </div>
  );
};
