// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import Navigation from "./Navigation";
import { FooterBar } from "./Footer";
import { SeoPageBlock } from "./seo-page-block";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/App.css";
import "../assets/css/index.css";
import "../assets/css/containers.css"
import "../assets/css/utiltiy.css"
import ReactGa from "react-ga";
import CookieConsent from "react-cookie-consent";

export default ({ children }) => {
  return (
    <>
      <Navigation />
      {children}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#1c75bc" }}
        buttonStyle={{
          background: "#2e8540",
          color: "#ffffff",
          fontSize: "15px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <SeoPageBlock/>
      <FooterBar />
    </>
  );
};
