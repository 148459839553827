import React from "react";
import { Row, Col } from "react-bootstrap";
import "../assets/css/Footer.css";
import "../assets/css/Section.css";
import { Link } from 'gatsby'


export const SeoPageBlock = () => (
  <>
<div class="grey-segment">
  <div class="text-left">
    <h4 class="pb-3 heading-color">We provide commercial heating services all over London, Hertfordshire and the surrounding areas</h4>
    <h5 class="pb-3">- With over 50 years of experience in the domestic and commercial heating industry, get in contact today to find out how we can help.</h5>
  </div>
</div>  
<div class="white-segment">
    <div class="link-font-size">
    <Row>
      <Col sm>
        <p><Link to="/commercial-boiler-services-london/">Commercial boiler servicing London</Link></p>
        <p><Link to="/commercial-boiler-services-marylebone/">Commercial boiler servicing Marylebone</Link></p>
        <p><Link to="/commercial-boiler-services-hampstead/">Commercial boiler servicing Hampstead</Link></p>
        <p><Link to="/commercial-boiler-services-camden/">Commercial boiler servicing Camden</Link></p>
        <p><Link to="/commercial-boiler-services-st-johns-wood/">Commercial boiler servicing St John's Wood</Link></p>
        <p><Link to="/commercial-boiler-services-barnet/">Commercial boiler servicing Barnet</Link></p>
        <p><Link to="/commercial-boiler-services-potters-bar/">Commercial boiler servicing Potters Bar</Link></p>
        <br></br>
      </Col>
      <Col sm>
      <p><Link to="/commercial-boiler-services-london/">Commercial boiler installation London</Link></p>
      <p><Link to="/commercial-boiler-services-marylebone/">Commercial boiler installation Marylebone</Link></p>
      <p><Link to="/commercial-boiler-services-hampstead/">Commercial boiler installation Hampstead</Link></p>
      <p><Link to="/commercial-boiler-services-camden/">Commercial boiler installation Camden</Link></p>
      <p><Link to="/commercial-boiler-services-st-johns-wood/">Commercial boiler installation St John's Wood</Link></p>
      <p><Link to="/commercial-boiler-services-barnet/">Commercial boiler installation Barnet</Link></p>
      <p><Link to="/commercial-boiler-services-potters-bar/">Commercial boiler installation Potters Bar</Link></p>
      <br></br>
      </Col>
      <Col sm>
      <p><Link to="/commercial-boiler-services-london/">Commercial boiler repair London</Link></p>
      <p><Link to="/commercial-boiler-services-marylebone/">Commercial boiler repair Marylebone</Link></p>
      <p><Link to="/commercial-boiler-services-hampstead/">Commercial boiler repair Hampstead</Link></p>
      <p><Link to="/commercial-boiler-services-camden/">Commercial boiler repair Camden</Link></p>
      <p><Link to="/commercial-boiler-services-st-johns-wood/">Commercial boiler repair St John's Wood</Link></p>
      <p><Link to="/commercial-boiler-services-barnet/">Commercial boiler repair Barnet</Link></p>
      <p><Link to="/commercial-boiler-services-potters-bar/">Commercial boiler repair Potters Bar</Link></p>
      </Col>
    </Row>
   </div>
</div>
</>
);
